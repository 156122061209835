import { AnalyticsRecordItemType } from '@/types/analytics'
import { Option } from '@/types/common'
import { SiteType } from '@/types/siteList'
import { sortBy } from 'lodash'
import { ALL_OPTION } from '@/constants/common'

export type InitiativesByCountryType = Option & {
  initiativesCount: number
}

type Filters = {
  report_period?: Option
  status?: Option
  country_code?: Option
  function_code?: Option
}

export const getCountriesWithInitiatives = (
  data: AnalyticsRecordItemType[],
  countries: Option[]
): InitiativesByCountryType[] => {
  return countries.map(country => {
    const initiativesCount = data.reduce((result, current) => {
      if (current.country_code === country.value) return result + current.initiatives_count

      return result
    }, 0)

    return {
      ...country,
      initiativesCount
    }
  })
}

export const getUnitsWithInitiatives = (
  data: AnalyticsRecordItemType[],
  sites: SiteType[],
  filters: Filters
): InitiativesByCountryType[] => {
  return sortBy(
    sites
      .filter(item => {
        const byCountry =
          filters.country_code?.value === ALL_OPTION.value ? true : item.country_code === filters.country_code?.value
        const byFunctionCode =
          filters.function_code?.value === ALL_OPTION.value ? true : item.function_code === filters.function_code?.value
        return byCountry && byFunctionCode
      })
      .map(site => {
        const initiativesCount = data.reduce((result, current) => {
          if (current.site === site.site_name) return result + current.initiatives_count

          return result
        }, 0)

        return {
          id: site.sustain_site_id,
          value: site.site_name,
          label: site.site_name,
          initiativesCount
        }
      }),
    'value'
  )
}
