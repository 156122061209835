import { AnalyticsRecordItemType, ImpactType } from '@/types/analytics'
import { cloneDeep, sortBy } from 'lodash'
import { Option } from '@/types/common'

type TableDataListType = AnalyticsRecordItemType & { label: string; isHighlighted: boolean }
export type TableDataType = {
  total: {
    participants_count: number
    coworkers_engaged_count: number
    customers_engaged_count: number
    change_makers_count: number
  }
  data: TableDataListType[]
}

export const getTableData = (
  data: AnalyticsRecordItemType[],
  impactType: ImpactType,
  countries: Option[],
  showByUnit: boolean
): TableDataType => {
  const copy = cloneDeep(data)
  const aggregatedData = sortBy(
    copy.filter(item => item.impact_type === impactType),
    'site'
  ).reduce((result: AnalyticsRecordItemType[], current) => {
    const index = result.findIndex(item => {
      if (showByUnit) {
        return item.site === current.site && item.function_code === current.function_code
      }
      return item.country_code === current.country_code
    })

    if (index !== -1) {
      result[index].participants_count += current.participants_count
      result[index].coworkers_engaged_count += current.coworkers_engaged_count
      result[index].customers_engaged_count += current.customers_engaged_count
      result[index].change_makers_count += current.change_makers_count

      return result
    }

    const country = countries.find(item => item.value === current.country_code)

    const label = showByUnit ? current.site || `${country?.label} - ${current.function_code}` : country?.label

    return [
      ...result,
      {
        ...current,
        label,
        isHighlighted: showByUnit ? !current.site : false
      }
    ]
  }, []) as TableDataListType[]

  const total = aggregatedData.reduce(
    (result, current) => {
      return {
        participants_count: result.participants_count + current.participants_count,
        coworkers_engaged_count: result.coworkers_engaged_count + current.coworkers_engaged_count,
        customers_engaged_count: result.customers_engaged_count + current.customers_engaged_count,
        change_makers_count: result.change_makers_count + current.change_makers_count
      }
    },
    {
      participants_count: 0,
      coworkers_engaged_count: 0,
      customers_engaged_count: 0,
      change_makers_count: 0
    }
  )

  return {
    data: aggregatedData,
    total
  }
}
