import React from 'react'
import Text from '@ingka/text'

import styles from '../../styles/Modal.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'

export const Coworkers: React.FC = () => {
  return (
    <>
      <Text tagName="h1" headingSize="l">
        <DynamicTranslations id="Change maker Co-worker" />
      </Text>
      <Text bodySize="l" style={{ marginBottom: '20px' }}>
        <DynamicTranslations id="guide.coworker.body1" withTags />
      </Text>
      <div className={styles.Table}>
        <DynamicTranslations id="guide.coworker.table_head" className={styles.TableRowHead} withTags />
        <DynamicTranslations id="guide.coworker.table_body" className={styles.TableRow} withTags />
      </div>
    </>
  )
}
