import React from 'react'
import Text from '@ingka/text'

import styles from '../../styles/Modal.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'

export const CoworkerHours: React.FC = () => {
  return (
    <>
      <Text tagName="h1" headingSize="l">
        <DynamicTranslations id="guide.coworker_hours.title" />
      </Text>
      <div className={styles.Table}>
        <DynamicTranslations id="guide.coworker_hours.body1" className={styles.TableRowHead} withTags />
        <DynamicTranslations id="guide.coworker_hours.body2" className={styles.TableRow} withTags />
        <DynamicTranslations id="guide.coworker_hours.body3" className={styles.TableRow} withTags />
      </div>
    </>
  )
}
