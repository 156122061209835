export const PEOPLE_IMPACTED_OPTIONS = [
  {
    id: 'pi-children',
    value: 'Vulnerable Children',
    label: 'Children'
  },
  {
    id: 'pi-youth',
    value: 'Vulnerable Youth',
    label: 'Youth'
  },
  {
    id: 'pi-older-persons',
    value: 'Older Persons',
    label: 'Older Persons'
  },
  {
    id: 'pi-disability',
    value: 'People With Disability',
    label: 'People With Disability'
  },
  {
    id: 'pi-migrants',
    value: 'Migrants',
    label: 'Migrants'
  },
  {
    id: 'pi-stateless',
    value: 'Refugees, asylum seekers and stateless persons',
    label: 'Refugees, asylum seekers and stateless persons'
  },
  {
    id: 'pi-workers',
    value: 'Informal and precarious workers',
    label: 'Informal and precarious workers'
  },
  {
    id: 'pi-LGBTQI+',
    value: 'LGBTQI+',
    label: 'LGBTQI+'
  },
  {
    id: 'pi-indigenous-people',
    value: 'Indigenous peoples',
    label: 'Indigenous peoples'
  },
  {
    id: 'pi-minorities',
    value: 'Minorities',
    label: 'Minorities'
  },
  {
    id: 'pi-rural',
    value: 'Rural workers and rural populations',
    label: 'Rural workers and rural populations'
  },
  {
    id: 'pi-chronic-illnesses',
    value: 'Persons living with chronic illnesses',
    label: 'Persons living with chronic illnesses'
  },
  {
    id: 'pi-low-income',
    value: 'Low income',
    label: 'Low income'
  },
  {
    id: 'pi-domestic-violence',
    value: 'Domestic Violence',
    label: 'Domestic Violence'
  },
  {
    id: 'pi-vulnerable-women',
    value: 'Vulnerable women',
    label: 'Women'
  },
  {
    id: 'pi-homelessness',
    value: 'People experiencing homelessness/poor home conditions',
    label: 'People experiencing homelessness/poor home conditions'
  },
  {
    id: 'pi-other',
    value: 'Other',
    label: 'Other:'
  }
]

export const MOVEMENT_OPTIONS = [
  {
    id: 'move-better-homes',
    value: 'Increasing access to better homes',
    label: 'Increasing access to better homes'
  },
  {
    id: 'move-pathways',
    value: 'Creating pathways to decent work',
    label: 'Creating pathways to decent work'
  },
  {
    id: 'move-participate',
    value: 'Enabling the many people to participate',
    label: 'Enabling the many people to participate'
  },
  {
    id: 'move-support',
    value: 'Emergency Support',
    label: 'Emergency Support'
  }
]

export const TYPE_OF_INITIATIVE_OPTIONS = [
  {
    id: 'type-emergency-donations',
    value: 'Emergency donations',
    label: 'Emergency donations'
  },
  {
    id: 'type-in-kind-donation',
    value: 'In-kind donations',
    label: 'In-kind donations'
  },
  {
    id: 'type-cash-donation',
    value: 'Cash donations',
    label: 'Cash donations'
  },
  {
    id: 'type-re-up-skilling',
    value: 'Upskilling and reskilling for employment',
    label: 'Upskilling and reskilling for employment'
  },
  {
    id: 'type-partnering',
    value: 'Partnering with social enterprises',
    label: 'Partnering with social enterprises'
  },
  {
    id: 'type-co-worker-engagement',
    value: 'Co-worker engagement',
    label: 'Co-worker engagement'
  },
  {
    id: 'type-customer-engagement',
    value: 'Customer engagement',
    label: 'Customer engagement'
  }
]

export const LEVEL_OF_CHANGE_OPTIONS = [
  {
    id: 'lvl-limited',
    value: 'Limited',
    label: 'Limited'
  },
  {
    id: 'lvl-substantive',
    value: 'Substantive',
    label: 'Substantive'
  },
  {
    id: 'lvl-significant',
    value: 'Significant',
    label: 'Significant'
  }
]

export const DURATION_OPTIONS = [
  {
    id: 'dur-long',
    value: 'Long term',
    label: 'Long term'
  },
  {
    id: 'dur-medium',
    value: 'Medium term',
    label: 'Medium term'
  },
  {
    id: 'dur-short',
    value: 'Short term',
    label: 'Short term'
  }
]
