import React from 'react'
import {
  getInitiatives,
  subscribeForInitiative,
  subscribeForInitiatives,
  subscribeForInitiativesLeft
} from '@/pages/Initiatives/services'
import { RootState, store } from '@/storage/store'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import {
  mapFromFilters,
  mapInitiativesToCount,
  mapToList,
  mapToListWithCreateInitiativePermission
} from '@/pages/Initiatives/utils/mapper'
import { useSelector } from 'react-redux'
import { loadingSlice } from '@/storage/loading'
import { Placeholder } from '@/pages/Initiatives/components/Placeholder'
import { isEmpty } from 'lodash'
import { Loader } from '@/components/loader/Loader'
import { Unsubscribe } from '@firebase/firestore'
import { USER_ROLES } from '@/constants/common'
import { useCanCreateInitiative } from '@/utils/permissions'
import { useSearchParams } from 'react-router-dom'
import { MANAGER_STATUSES } from '@/constants/statuses'

export const INITIATIVES_LOADER = 'INITIATIVES_LOADER'
export const INITIATIVES_CHECK_LOADER = 'INITIATIVES_CHECK_LOADER'

const statusForCounter = {
  [USER_ROLES.countryManager]: MANAGER_STATUSES[1],
  [USER_ROLES.globalManager]: MANAGER_STATUSES[2]
}

export const InitiativesProvider: React.FC<React.PropsWithChildren> = props => {
  const { children } = props
  const user = useSelector((state: RootState) => state.auth.user)
  const [isEmptyList, setIsEmptyList] = React.useState(false)
  const filters = useSelector((state: RootState) => state.filters.filters)
  const unsub = React.useRef<Unsubscribe | null>(null)
  const unsubPreview = React.useRef<Unsubscribe | null>(null)
  const unsubCounter = React.useRef<Unsubscribe | null>(null)
  const [searchParams] = useSearchParams()

  const canCreateInitiative = useCanCreateInitiative()
  const previewId = searchParams.get('preview_id')

  React.useEffect(() => {
    if (user?.id && user.role === USER_ROLES.user) {
      store.dispatch(loadingSlice.actions.setLoading({ name: INITIATIVES_CHECK_LOADER, value: true }))

      getInitiatives(user?.id, {}).then(items => {
        setIsEmptyList(isEmpty(items))
        store.dispatch(loadingSlice.actions.setLoading({ name: INITIATIVES_CHECK_LOADER, value: false }))
      })
    }

    if (user?.role && user?.role !== USER_ROLES.user) {
      subscribeForInitiativesLeft(statusForCounter[user.role], initiatives => {
        const count = mapInitiativesToCount(
          initiatives,
          user?.id,
          user.country || [],
          user.role,
          Boolean(user.is_centres)
        )
        store.dispatch(initiativesSlice.actions.setInitiativesLeftCount(count || 0))
      })
    }

    return () => {
      unsub.current && unsub.current()
      unsubPreview.current && unsubPreview.current()
      unsubCounter.current && unsubCounter.current()
    }
  }, [])

  React.useEffect(() => {
    if (unsubPreview.current) unsubPreview.current()

    if (previewId) {
      unsubPreview.current = subscribeForInitiative(previewId, initiative => {
        const [mapped] = mapToList([initiative])
        store.dispatch(initiativesSlice.actions.setPreviewDetails(mapped))
      })
    }
  }, [previewId])

  React.useEffect(() => {
    if (!user?.id) return
    store.dispatch(loadingSlice.actions.setLoading({ name: INITIATIVES_LOADER, value: true }))

    // Filters|Conditions block
    const filtersMapped = mapFromFilters(filters)
    if (unsub.current) unsub.current()

    const isDataProvider = user?.role === USER_ROLES.user
    const userId = isDataProvider ? user?.id || null : null
    const countryCode = user?.role === USER_ROLES.countryManager ? user?.country || null : null
    const isManager = user?.role === USER_ROLES.countryManager || user?.role === USER_ROLES.globalManager

    unsub.current = subscribeForInitiatives(
      filtersMapped,
      userId,
      countryCode,
      canCreateInitiative,
      isDataProvider,
      Boolean(user.is_centres),
      initiatives => {
        const mapped = canCreateInitiative
          ? mapToListWithCreateInitiativePermission(
              initiatives,
              filtersMapped,
              user?.id,
              user?.country || null,
              isManager,
              Boolean(user.is_centres)
            )
          : mapToList(initiatives, isManager)

        store.dispatch(initiativesSlice.actions.setInitiatives(mapped))
        store.dispatch(loadingSlice.actions.setLoading({ name: INITIATIVES_LOADER, value: false }))
      }
    )
  }, [user?.id, JSON.stringify(filters), canCreateInitiative])

  if (isEmptyList) return <Placeholder />

  return (
    <Loader name={INITIATIVES_CHECK_LOADER}>
      <Loader name={INITIATIVES_LOADER}>{children}</Loader>
    </Loader>
  )
}
