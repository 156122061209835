import { FiltersBEType } from '@/types/filters'
import { AnalyticsRecordDBType } from '@/types/analytics'
import { onSnapshot, QueryConstraint } from '@firebase/firestore'
import { collection, query, where } from 'firebase/firestore'
import { db } from '@/store'
import { getLatestVersion } from '@/utils/getLatestVersion'

export const subscribeForDataReporting = (
  filters: FiltersBEType,
  callback: (items: AnalyticsRecordDBType[]) => void
) => {
  const queryParts: QueryConstraint[] = Object.keys(filters).map(key => {
    return where(key, '==', filters[key as keyof FiltersBEType])
  })

  return onSnapshot(query(collection(db, 'data_reporting'), ...queryParts), async querySnapshot => {
    const result: Promise<AnalyticsRecordDBType>[] = []

    querySnapshot.forEach(item => {
      result.push(getLatestVersion<AnalyticsRecordDBType>(item))
    })

    const items = await Promise.all(result)
    callback(items)
  })
}
