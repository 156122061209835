import { addMonths, add, format } from 'date-fns'
import { Option } from '@/types/common'

export function getIngkaFinancialYear(date: Date) {
  // Ingka starts its financial year in September, so adding four months so calendar year matches it
  return add(date, { months: 4 })
}

export function getCurrentPeriod(date: Date): number {
  if (!date) return NaN
  // First month/period in FY will be September and last one will be August
  const currentMonth = date?.getMonth() + 1
  return currentMonth > 8 ? currentMonth - 8 : currentMonth + 4
}

export const getCurrentFYOptions = (): Option[] => {
  const fy = getIngkaFinancialYear(new Date())
  const list = []

  // const periodCount = getCurrentPeriod(new Date())
  // let period = periodCount
  // const initialDate = new Date(fy.getFullYear() - 1, 8, 1)
  const prevInitialDate = new Date(fy.getFullYear() - 2, 8, 1)

  let prevPeriod = 12

  while (prevPeriod > 0) {
    const date = addMonths(prevInitialDate, 12 - prevPeriod)
    list.push({
      id: format(Number(date), 'yyyy-MM-dd'),
      value: format(Number(date), 'yyyy-MM-dd'),
      label: `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`
    })
    prevPeriod--
  }

  // Remove this until November
  // while (period > 0) {
  //   const date = addMonths(initialDate, periodCount - period)
  //   list.push({
  //     id: format(Number(date), 'yyyy-MM-dd'),
  //     value: format(Number(date), 'yyyy-MM-dd'),
  //     label: `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`
  //   })
  //   period--
  // }

  return list.reverse()
}
