import { InitiativesStatusesType } from '@/types/initiative'

export const STATUSES: InitiativesStatusesType[] = [
  'rejected',
  'submitted',
  'global_review',
  'draft',
  'ready_for_push',
  'pushed'
]

export const EDIT_WITH_STATUSES: InitiativesStatusesType[] = ['rejected', 'submitted', 'draft']

export const MANAGER_STATUSES: InitiativesStatusesType[] = ['rejected', 'submitted', 'global_review', 'ready_for_push']

export const STATUSES_OPTIONS = [
  {
    id: STATUSES[0],
    value: STATUSES[0],
    label: 'status.rejected'
  },
  {
    id: STATUSES[1],
    value: STATUSES[1],
    label: 'status.submitted'
  },
  {
    id: STATUSES[2],
    value: STATUSES[2],
    label: 'status.global_review'
  },
  {
    id: STATUSES[3],
    value: STATUSES[3],
    label: 'status.draft'
  },
  {
    id: STATUSES[4],
    value: STATUSES[4],
    label: 'status.ready_for_push'
  }
]

// Without Draft status
export const MANAGER_STATUSES_OPTIONS = [
  STATUSES_OPTIONS[0],
  STATUSES_OPTIONS[1],
  STATUSES_OPTIONS[2],
  STATUSES_OPTIONS[4]
]

export const STATUS_TO_LABEL = {
  [STATUSES[0]]: 'Rejected',
  [STATUSES[1]]: 'Country Review',
  [STATUSES[2]]: 'Global Review',
  [STATUSES[3]]: 'Draft',
  [STATUSES[4]]: 'Ready',
  [STATUSES[5]]: 'Pushed'
}

export const TOAST_STATUS_CHANGED = 'Initiative status changed to'
export const TOAST_INITIATIVE_COMMENTED = 'Added new comment to initiative'
