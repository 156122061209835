import { FormikHelpers } from 'formik/dist/types'
import { InitiativeFormType, InitiativesStatusesType } from '@/types/initiative'
import { mapDataToSubmit } from '@/pages/ReportForm/utils/mappers'
import { editInitiative, setInitiative } from '@/pages/ReportForm/services'
import { initiativesSlice } from '@/pages/Initiatives/slices/initiatives'
import { store } from '@/storage/store'
import { mapToList } from '@/pages/Initiatives/utils/mapper'
import { trackActivity } from '@/pages/Activity/utils/trackActivity'
import { ACTIVITY_TYPES } from '@/constants/common'
import { showToast } from '@/components/toast/ToastContainer'
import { omit } from 'lodash'

export const submitData =
  (type: string, afterSubmit: () => void) =>
  async (data: InitiativeFormType, handlers: FormikHelpers<InitiativeFormType>) => {
    const user = store.getState().auth.user
    const attachments = store.getState().form.attachments
    if (!user || attachments.some(item => item.error) || attachments.some(item => item.progress < 100)) return

    const mapped = mapDataToSubmit(data, user.id, type as InitiativesStatusesType, attachments)

    const initiative = await setInitiative(mapped)
    const [details] = mapToList([initiative])
    await trackActivity({
      type: ACTIVITY_TYPES.status,
      user_id: user.id,
      initiative_id: initiative.id,
      status: initiative.status
    })

    store.dispatch(initiativesSlice.actions.changeDetails(details))
    handlers.setSubmitting(false)
    afterSubmit()
    showToast('Initiative successfully created')
  }

export const editData =
  (id: string, type: string, afterSubmit: () => void) =>
  async (data: InitiativeFormType, handlers: FormikHelpers<InitiativeFormType>) => {
    const user = store.getState().auth.user
    const attachments = store.getState().form.attachments
    if (!user || attachments.some(item => item.error) || attachments.some(item => item.progress < 100)) {
      handlers.setSubmitting(false)
      return
    }

    const mapped = mapDataToSubmit(data, user.id, type as InitiativesStatusesType, attachments)

    const initiative = await editInitiative(id, omit(mapped, 'user_id'))
    const [details] = mapToList([initiative])

    await trackActivity({
      type: ACTIVITY_TYPES.status,
      user_id: user.id,
      initiative_id: initiative.id,
      status: initiative.status,
      ...(data.submitReason ? { comment: data.submitReason } : {})
    })

    store.dispatch(initiativesSlice.actions.changeDetails(details))

    handlers.setSubmitting(false)
    afterSubmit()
    showToast('Initiative successfully updated')
  }

export const applyAttachmentDelete = async (id: string) => {
  const attachments = store.getState().form.attachments
  await editInitiative(id, { attachments })
}
