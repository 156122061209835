import * as Yup from 'yup'

import { FIELD_NAME } from '../constants/types'
import { MOVEMENT_OPTIONS } from '../constants/options'
import { Option } from '@/types/common'

const REQUIRED = 'Field is required'
const NUMBER = 'You should type number'
const INTEGER = 'Numbers with decimals are not allowed'
const MAX_SYMBOLS = 'You exceeded max number of symbols 400'
const MAX_SYMBOLS_TEXT_AREA = 'You exceeded max number of symbols 2500'
const MIN_SYMBOLS_DESCRIPTION = 'Minimum number of symbols is 140'

const COWORKERS_ZERO = 'Coworkers engage value is equal to zero so hours should also be zero'
const BUDGET_SPENT =
  'Amount spent should not be less than 15 EUR if both coworkers and customers/citizens are equal to zero'

export const INPUT_SYMBOLS_MAX = 400
export const TEXT_AREA_SYMBOLS_MAX = 2500
export const DESCRIPTION_MIN_SYMBOLS = 140

export const NewReportSchema = Yup.object().shape({
  [FIELD_NAME.country]: Yup.object().required(REQUIRED),
  [FIELD_NAME.siteOrCountry]: Yup.object().required(REQUIRED),
  [FIELD_NAME.title]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS).required(REQUIRED),
  [FIELD_NAME.description]: Yup.string()
    .min(DESCRIPTION_MIN_SYMBOLS, MIN_SYMBOLS_DESCRIPTION)
    .max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA)
    .required(REQUIRED),
  [FIELD_NAME.report_period]: Yup.string().required(REQUIRED),
  [FIELD_NAME.partner]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS).required(REQUIRED),
  [FIELD_NAME.people_impacted]: Yup.array().min(1, REQUIRED).required(REQUIRED),
  [FIELD_NAME.people_impacted_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.people_supported_count]: Yup.number().integer(INTEGER).typeError(NUMBER).required(REQUIRED),
  [FIELD_NAME.movement_supported]: Yup.array().min(1, REQUIRED).required(REQUIRED),
  [FIELD_NAME.type_of_initiative]: Yup.array().min(1, REQUIRED).required(REQUIRED),
  [FIELD_NAME.type_of_initiative_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.spent]: Yup.number()
    .typeError(NUMBER)
    .required(REQUIRED)
    .test('less-than-15', BUDGET_SPENT, (value, context) => {
      return !(
        value < 15 &&
        context.parent[FIELD_NAME.coworkers_count] === 0 &&
        context.parent[FIELD_NAME.customers_count] === 0 &&
        !context.parent[FIELD_NAME.movement_supported].some((item: Option) => item.id === MOVEMENT_OPTIONS[1].id)
      )
    }),
  [FIELD_NAME.level]: Yup.object().required(REQUIRED),
  [FIELD_NAME.duration]: Yup.object().required(REQUIRED),
  [FIELD_NAME.coworkers_count]: Yup.number().integer(INTEGER).typeError(NUMBER).required(REQUIRED),
  [FIELD_NAME.coworker_hours]: Yup.number()
    .typeError(NUMBER)
    .required(REQUIRED)
    .test('dependent', COWORKERS_ZERO, (value, context) => {
      return !(value !== 0 && context.parent[FIELD_NAME.coworkers_count] === 0)
    }),
  [FIELD_NAME.customers_count]: Yup.number().integer(INTEGER).typeError(NUMBER).required(REQUIRED)
})

export const DraftReportSchema = Yup.object().shape({
  [FIELD_NAME.country]: Yup.object().required(REQUIRED),
  [FIELD_NAME.siteOrCountry]: Yup.object().required(REQUIRED),
  [FIELD_NAME.title]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS).required(REQUIRED),
  [FIELD_NAME.description]: Yup.string()
    .min(DESCRIPTION_MIN_SYMBOLS, MIN_SYMBOLS_DESCRIPTION)
    .max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.partner]: Yup.string().max(INPUT_SYMBOLS_MAX, MAX_SYMBOLS),
  [FIELD_NAME.people_supported_count]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.people_impacted_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.type_of_initiative_comment]: Yup.string().max(TEXT_AREA_SYMBOLS_MAX, MAX_SYMBOLS_TEXT_AREA),
  [FIELD_NAME.spent]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.coworkers_count]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.coworker_hours]: Yup.number().nullable().typeError(NUMBER),
  [FIELD_NAME.customers_count]: Yup.number().nullable().typeError(NUMBER)
})
