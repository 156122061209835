import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Option } from '@/types/common'
import { FiltersType } from '@/types/filters'
import { ALL_OPTION } from '@/constants/common'

type StoreType = {
  filters: {
    report_period?: Option
    status?: Option
    country_code?: Option
    function_code?: Option
  }
}

const initialState = {
  filters: {
    report_period: ALL_OPTION,
    status: ALL_OPTION,
    country_code: ALL_OPTION,
    function_code: ALL_OPTION
  }
} as StoreType

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<FiltersType>) {
      state.filters = {
        ...state.filters,
        ...action.payload
      }
    }
  }
})
