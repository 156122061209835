export const FILTER_NAME = {
  country_code: 'country_code',
  site: 'site',
  report_period: 'report_period',
  people_impacted: 'people_impacted',
  people_supported_count: 'people_supported_count',
  movement_supported: 'movement_supported',
  type_of_initiative: 'type_of_initiative',
  spent: 'spent',
  level: 'level',
  duration: 'duration',
  status: 'status',
  function_code: 'function_code'
}

export const EXPLORE_LOADING = 'EXPLORE_LOADING'
