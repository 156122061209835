import { AnalyticsRecordItemType } from '@/types/analytics'
import { ALL_OPTION, IMPACT_TYPES } from '@/constants/common'
import { Option } from '@/types/common'
import { SiteType } from '@/types/siteList'

export const getAnalysisSeries = (data: AnalyticsRecordItemType[], withConnect?: boolean) => {
  const improve = data
    .filter(item => item.impact_type === IMPACT_TYPES.improve)
    .reduce((result, current) => result + current.initiatives_count, 0)
  const transform = data
    .filter(item => item.impact_type === IMPACT_TYPES.transform)
    .reduce((result, current) => result + current.initiatives_count, 0)

  const series = [
    {
      name: 'data_reporting.analysis.improve',
      color: '#489CE3',
      value: improve
    },
    {
      name: 'data_reporting.analysis.transform',
      color: '#F26A2F',
      value: transform
    }
  ]

  if (withConnect) {
    const connect = data
      .filter(item => item.impact_type === IMPACT_TYPES.connect)
      .reduce((result, current) => result + current.initiatives_count, 0)

    series.push({
      name: 'data_reporting.analysis.connect',
      color: '#E52F7E',
      value: connect
    })
  }

  return series
}

type Filters = {
  report_period?: Option
  status?: Option
  country_code?: Option
  function_code?: Option
}

export const getReportingStatusCountries = (data: AnalyticsRecordItemType[], countries: Option[]) => {
  const reportedCount = data.reduce((result, current) => {
    if (result.find(item => item === current.country_code)) return result
    return [...result, current.country_code]
  }, [] as string[]).length
  const notReportedCount = countries.length - reportedCount

  return [
    {
      name: 'data_reporting.reporting_status.countries_reported',
      color: '#003E72',
      value: reportedCount
    },
    {
      name: 'data_reporting.reporting_status.countries_not_reported',
      color: '#CCC',
      value: notReportedCount
    }
  ]
}

export const getReportedStatusUnits = (data: AnalyticsRecordItemType[], sites: SiteType[], filters: Filters) => {
  const reportedCount = data.reduce((result, current) => {
    if (!current.site) return result
    if (result.find(item => item === current.site)) return result
    return [...result, current.site]
  }, [] as string[]).length
  const notReportedCount =
    sites.filter(item => {
      const byCountry =
        filters.country_code?.value === ALL_OPTION.value ? true : item.country_code === filters.country_code?.value
      const byFunctionCode =
        filters.function_code?.value === ALL_OPTION.value ? true : item.function_code === filters.function_code?.value
      return byCountry && byFunctionCode
    }).length - reportedCount

  return [
    {
      name: 'data_reporting.reporting_status.units_reported',
      color: '#003E72',
      value: reportedCount
    },
    {
      name: 'data_reporting.reporting_status.units_not_reported',
      color: '#CCC',
      value: notReportedCount
    }
  ]
}
