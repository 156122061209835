import { collection, getDocs, where, query, getDoc, doc, setDoc, orderBy } from 'firebase/firestore'
import { onSnapshot, QueryConstraint, updateDoc } from '@firebase/firestore'
import { db } from '@/store'
import { InitiativeDBType, InitiativesStatusesType } from '@/types/initiative'
import { getLatestVersion } from '@/utils/getLatestVersion'
import { FiltersBEType } from '@/types/filters'
import { STATUSES } from '@/constants/statuses'
import { changeUpdatedAt } from '@/utils/db'
import { FUNCTIONS_CODES, MANAGER_FUNCTION_CODES, USER_ROLES } from '@/constants/common'
import { Role } from '@/types/user'

export const subscribeForInitiative = (initiativeId: string, callback: (initiative: InitiativeDBType) => void) => {
  return onSnapshot(doc(db, 'initiatives', initiativeId), async querySnapshot => {
    callback(await getLatestVersion<InitiativeDBType>(querySnapshot))
  })
}

export const getInitiatives = async (userId: string, filters: FiltersBEType): Promise<InitiativeDBType[]> => {
  const queryParts: QueryConstraint[] = [orderBy('updated_at', 'desc'), where('user_id', '==', userId)]

  Object.keys(filters).forEach(key => {
    queryParts.push(where(key, '==', filters[key as keyof FiltersBEType]))
  })

  const result = await getDocs(query(collection(db, 'initiatives'), ...queryParts))
  return Promise.all(result.docs.map(getLatestVersion<InitiativeDBType>))
}

export const subscribeForInitiatives = (
  filters: FiltersBEType,
  userId: string | null,
  country: string[] | null,
  canCreateInitiative: boolean,
  isDataProvider: boolean,
  centersOnly: boolean,
  callback: (items: InitiativeDBType[]) => void
) => {
  const queryParts: QueryConstraint[] = [orderBy('updated_at', 'desc')]

  if (userId && isDataProvider) {
    queryParts.push(where('user_id', '==', userId))
  }

  if (country && !canCreateInitiative && !centersOnly) {
    queryParts.push(where('country_code', 'in', country))
  }

  if (centersOnly && !isDataProvider) {
    queryParts.push(where('function_code', '==', FUNCTIONS_CODES.centers))
  }

  if (!centersOnly && !isDataProvider) {
    queryParts.push(where('function_code', 'in', MANAGER_FUNCTION_CODES))
  }

  Object.keys(filters).forEach(key => {
    queryParts.push(where(key, '==', filters[key as keyof FiltersBEType]))
  })

  return onSnapshot(query(collection(db, 'initiatives'), ...queryParts), async querySnapshot => {
    const result: Promise<InitiativeDBType>[] = []

    querySnapshot.forEach(item => {
      result.push(getLatestVersion<InitiativeDBType>(item))
    })

    const items = await Promise.all(result)
    callback(items)
  })
}

export const updateInitiativeStatus = async (id: string, status: InitiativesStatusesType, updated_by: string) => {
  const initiative = await getDoc(doc(db, 'initiatives', id))
  const docRef = doc(db, 'initiatives', id)
  await setDoc(docRef, {
    ...initiative.data(),
    status,
    updated_by
  })

  await changeUpdatedAt(docRef)
}

export const rejectInitiative = async (
  id: string,
  status: InitiativesStatusesType = STATUSES[0],
  updated_by: string,
  role: Role
) => {
  const rejected_by_global = role === USER_ROLES.globalManager

  const initiative = await getDoc(doc(db, 'initiatives', id))
  const initiativeData = initiative.data()
  const docRef = doc(db, 'initiatives', id)
  await setDoc(doc(db, 'initiatives', id), {
    ...initiativeData,
    status,
    updated_by,
    rejected_by_global: initiativeData?.rejected_by_global || rejected_by_global
  })

  await changeUpdatedAt(docRef)
}

export const editInitiative = async (id: string, data: Partial<InitiativeDBType>) => {
  const docRef = doc(db, 'initiatives', id)
  await updateDoc(docRef, data)
  await changeUpdatedAt(docRef)
}

export const subscribeForInitiativesLeft = (
  status: InitiativesStatusesType,
  callback: (items: InitiativeDBType[]) => void
) => {
  return onSnapshot(query(collection(db, 'initiatives'), where('status', '==', status)), async querySnapshot => {
    const result: Promise<InitiativeDBType>[] = []

    querySnapshot.forEach(item => {
      result.push(getLatestVersion<InitiativeDBType>(item))
    })

    const items = await Promise.all(result)
    callback(items)
  })
}
