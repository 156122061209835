import React from 'react'
import EvaluationTable from '@/images/EvaluationTable.png'
import { InputField } from '@/components/form/input/InputField'
import { TextArea } from '@/components/form/textarea/TextArea'
import { CheckboxGroup } from '@/components/form/checkbox/CheckboxGroup'

import {
  DURATION_OPTIONS,
  LEVEL_OF_CHANGE_OPTIONS,
  MOVEMENT_OPTIONS,
  PEOPLE_IMPACTED_OPTIONS,
  TYPE_OF_INITIATIVE_OPTIONS
} from '../constants/options'
import { RadioGroup } from '@/components/form/radio/RadioGroup'
import { FormLabel } from '@/pages/ReportForm/components/Labels'
import { Select } from '@/components/form/dropdown/Select'
import { getCurrentFYOptions } from '@/utils/dates'
import { FIELD_NAME } from '@/pages/ReportForm/constants/types'
import { OtherComment } from '@/pages/ReportForm/components/Fields/OtherComment'
import { TEXT_AREA_SYMBOLS_MAX } from '@/pages/ReportForm/utils/validation'
import { FormHeadInfo } from '@/pages/ReportForm/components/FormHeadInfo'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import styles from '../styles/NewReport.module.scss'
import { ScrollToError } from '@/components/form/error/ScrollToError'
import { SearchCommon } from '@/components/form/dropdown/SearchCommon'
import { Site } from '@/pages/ReportForm/components/Fields/Site'
import Text from '@ingka/text'
import { Attachments } from '@/pages/ReportForm/components/Fields/Attachments'
import { DynamicTranslations } from '@/components/translations/Translations'

export const ReportForm: React.FC = () => {
  const { countries } = useSelector((state: RootState) => state.sites)

  return (
    <>
      <FormHeadInfo />
      <div className={styles.Fields}>
        <SearchCommon
          label={<DynamicTranslations id="form.field.country.label" />}
          name={FIELD_NAME.country}
          id={FIELD_NAME.country}
          list={countries}
        />
        <Site />
        <InputField
          id={FIELD_NAME.title}
          name={FIELD_NAME.title}
          type="text"
          label={<DynamicTranslations id="form.field.title.label" />}
        />
        <Select
          id={FIELD_NAME.report_period}
          name={FIELD_NAME.report_period}
          label={<DynamicTranslations id="form.field.report_period.label" />}
          list={getCurrentFYOptions()}
          fieldHelper={{
            id: 'search-hlp',
            msg: <DynamicTranslations id="form.field.report_period.helper" />
          }}
        />
        <InputField
          id={FIELD_NAME.partner}
          name={FIELD_NAME.partner}
          type="text"
          label={<DynamicTranslations id="form.field.partner.label" />}
        />
        <CheckboxGroup
          name={FIELD_NAME.people_impacted}
          label={<DynamicTranslations id="form.field.people_impacted.label" />}
          list={PEOPLE_IMPACTED_OPTIONS}
          fieldHelper={{
            id: 'people-impacted-hlp',
            msg: <DynamicTranslations id="form.field.people_impacted.helper" />
          }}
          additionalElement={
            <OtherComment name={FIELD_NAME.people_impacted_comment} label="" characterLimit={TEXT_AREA_SYMBOLS_MAX} />
          }
        />
        <InputField
          id={FIELD_NAME.people_supported_count}
          type="number"
          name={FIELD_NAME.people_supported_count}
          label={<FormLabel id={FIELD_NAME.people_supported_count} />}
          fieldHelper={{
            id: 'count-hlp',
            msg: <DynamicTranslations id="form.field.people_supported_count.helper" />
          }}
        />
        <CheckboxGroup
          name={FIELD_NAME.movement_supported}
          label={<FormLabel id={FIELD_NAME.movement_supported} />}
          list={MOVEMENT_OPTIONS}
          fieldHelper={{
            id: 'movement-hlp',
            msg: <DynamicTranslations id="form.field.movement_supported.helper" />
          }}
        />
        <CheckboxGroup
          name={FIELD_NAME.type_of_initiative}
          label={<DynamicTranslations id="form.field.type_of_initiative.label" />}
          list={TYPE_OF_INITIATIVE_OPTIONS}
          fieldHelper={{
            id: 'type_of_other-hlp',
            msg: <DynamicTranslations id="form.field.type_of_initiative.helper" />
          }}
        />
        <InputField
          id={FIELD_NAME.spent}
          type="number"
          name={FIELD_NAME.spent}
          label={<FormLabel id={FIELD_NAME.spent} />}
          fieldHelper={{
            id: 'cost-hlp',
            msg: <DynamicTranslations id="form.field.spent.helper" />
          }}
        />
        <Text style={{ fontWeight: 'bold' }} bodySize="m">
          <DynamicTranslations id="form.field.image_helper.title" />
        </Text>
        <img className={styles.TableImg} src={EvaluationTable} alt="evaluation_img" />
        <RadioGroup
          name={FIELD_NAME.level}
          list={LEVEL_OF_CHANGE_OPTIONS}
          label={<FormLabel id={FIELD_NAME.level} />}
        />
        <RadioGroup name={FIELD_NAME.duration} list={DURATION_OPTIONS} label={<FormLabel id={FIELD_NAME.duration} />} />
        <InputField
          id={FIELD_NAME.coworkers_count}
          type="number"
          name={FIELD_NAME.coworkers_count}
          label={<FormLabel id={FIELD_NAME.coworkers_count} />}
          fieldHelper={{
            id: 'coworkers-hlp',
            msg: <DynamicTranslations id="form.field.coworkers_count.helper" />
          }}
        />
        <InputField
          id={FIELD_NAME.coworker_hours}
          type="number"
          name={FIELD_NAME.coworker_hours}
          label={<FormLabel id={FIELD_NAME.coworker_hours} />}
          fieldHelper={{
            id: 'hours-hlp',
            msg: <DynamicTranslations id="form.field.coworker_hours.helper" />
          }}
        />
        <InputField
          id={FIELD_NAME.customers_count}
          type="number"
          name={FIELD_NAME.customers_count}
          label={<FormLabel id={FIELD_NAME.customers_count} />}
          fieldHelper={{
            id: 'customers-hlp',
            msg: <DynamicTranslations id="form.field.customers_count.helper" />
          }}
        />
        <TextArea
          name={FIELD_NAME.description}
          label={<DynamicTranslations id="form.field.description.label" />}
          characterLimit={TEXT_AREA_SYMBOLS_MAX}
          customHelperText={<DynamicTranslations id="form.field.description.helper" />}
        />
        <TextArea
          name={FIELD_NAME.comment}
          label={<DynamicTranslations id="form.field.comment.label" />}
          fieldHelper={{
            id: 'comment-hlp',
            msg: <DynamicTranslations id="form.field.comment.helper" />
          }}
        />
        <Attachments />
        <ScrollToError />
      </div>
    </>
  )
}
