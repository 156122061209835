import { ExploreFiltersType } from '@/types/filters'
import { store } from '@/storage/store'
import { exploreSlice } from '@/pages/Explore/slices/explore'
import { FormikHelpers } from 'formik/dist/types'
import { cloneDeep, omit } from 'lodash'
import { Option } from '@/types/common'
import { loadingSlice } from '@/storage/loading'
import { EXPLORE_LOADING } from '@/pages/Explore/constants/filters'

export const handleFilterData = (values: ExploreFiltersType, helpers: FormikHelpers<ExploreFiltersType>) => {
  store.dispatch(exploreSlice.actions.setFilter(values))
  store.dispatch(exploreSlice.actions.setFiltersModal(false))
  helpers.setSubmitting(false)
}

export const clearFilters = () => {
  store.dispatch(exploreSlice.actions.setFilter({}))
  store.dispatch(loadingSlice.actions.setLoading({ name: EXPLORE_LOADING, value: true }))
}

export const clearSingleFilter = (key: keyof ExploreFiltersType, optionValue?: string) => () => {
  const filters = store.getState().explore.filters
  store.dispatch(loadingSlice.actions.setLoading({ name: EXPLORE_LOADING, value: true }))

  if (!optionValue) {
    store.dispatch(exploreSlice.actions.setFilter(omit(filters, key)))
    return
  }

  let clone = cloneDeep(filters)
  clone = {
    ...clone,
    [key]: (clone[key] as Option[]).filter(item => item.value !== optionValue)
  }

  store.dispatch(exploreSlice.actions.setFilter(clone))
}
