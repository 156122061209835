import { ExploreFiltersBEType } from '@/types/filters'
import { InitiativeDBType } from '@/types/initiative'
import { onSnapshot, QueryConstraint } from '@firebase/firestore'
import { collection, query, where } from 'firebase/firestore'
import { db } from '@/store'
import { getLatestVersion } from '@/utils/getLatestVersion'
import { FILTER_NAME } from '@/pages/Explore/constants/filters'

export const subscribeForInitiatives = (
  filters: ExploreFiltersBEType,
  callback: (items: InitiativeDBType[]) => void
) => {
  const queryParts: QueryConstraint[] = []

  ;(Object.keys(filters) as (keyof ExploreFiltersBEType)[]).forEach((key: keyof ExploreFiltersBEType) => {
    switch (key) {
      case FILTER_NAME.country_code:
      case FILTER_NAME.site:
        queryParts.push(where(key, '==', filters[key]))
        break

      case FILTER_NAME.spent:
      case FILTER_NAME.people_supported_count:
        queryParts.push(where(key, '==', filters[key]))
        break
    }
  })

  return onSnapshot(query(collection(db, 'initiatives'), ...queryParts), async querySnapshot => {
    const result: Promise<InitiativeDBType>[] = []

    querySnapshot.forEach(item => {
      result.push(getLatestVersion<InitiativeDBType>(item))
    })

    const items = await Promise.all(result)
    callback(items)
  })
}
