import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { ExploreFiltersType } from '@/types/filters'
import { FILTER_NAME } from '@/pages/Explore/constants/filters'
import { Option } from '@/types/common'
import Button from '@ingka/button'
import CloseIcon from '@ingka/ssr-icon/paths/cross-small'
import styles from '@/pages/Explore/styles/Explore.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'
import { Show } from '@/components/conditions/Show'
import { isEmpty } from 'lodash'
import { clearFilters, clearSingleFilter } from '@/pages/Explore/utils/filter'

export const ActiveFilters = () => {
  const filters = useSelector((state: RootState) => state.explore.filters)

  const list = React.useMemo(() => {
    return (Object.keys(filters) as (keyof ExploreFiltersType)[]).reduce((result, key) => {
      const value = filters[key as keyof ExploreFiltersType]

      switch (key) {
        case FILTER_NAME.country_code:
        case FILTER_NAME.site:
          return [...result, { key, label: (value as Option)?.label }]

        case FILTER_NAME.status:
        case FILTER_NAME.report_period:
        case FILTER_NAME.function_code:
        case FILTER_NAME.people_impacted:
        case FILTER_NAME.movement_supported:
        case FILTER_NAME.type_of_initiative:
        case FILTER_NAME.duration:
        case FILTER_NAME.level:
          return [...result, ...(value as Option[]).map(item => ({ key, label: item.label, value: item.value }))]

        default:
          return [...result, { key, label: (value as number).toString() }]
      }
    }, [] as { key: keyof ExploreFiltersType; label: string; value?: string }[])
  }, [filters])

  return (
    <div className={styles.ActiveFilters}>
      {list.map(item => (
        <Button
          key={`${item.key}-${item.label}`}
          type="tertiary"
          ssrIcon={CloseIcon}
          small
          className={styles.FilterListButton}
          onClick={clearSingleFilter(item.key, item.value)}
        >
          <Show when={item.key === FILTER_NAME.people_supported_count}>
            <DynamicTranslations id="explore.filters.list.people_supported_count" />:{' '}
          </Show>
          <Show when={item.key === FILTER_NAME.spent}>
            <DynamicTranslations id="explore.filters.list.spent" />:{' '}
          </Show>
          <DynamicTranslations id={item.label} />
        </Button>
      ))}
      <Show when={!isEmpty(list)}>
        <Button type="tertiary" small className={styles.FiltersButton} onClick={clearFilters}>
          <DynamicTranslations id="explore.filters.clear" />
        </Button>
      </Show>
    </div>
  )
}
