import React from 'react'
import Button from '@ingka/button'
import Text from '@ingka/text'
import BookmarkIcon from '@ingka/ssr-icon/paths/bookmark'
import InfoCircleIcon from '@ingka/ssr-icon/paths/information-circle'
import InlineMessage from '@ingka/inline-message'

import Logo from '@/images/IKEA_logo.svg'

import styles from '../styles/NewReport.module.scss'
import { Link, useParams } from 'react-router-dom'
import { Show } from '@/components/conditions/Show'
import { DynamicTranslations, useTranslation } from '@/components/translations/Translations'

export const FormHeadInfo: React.FC = () => {
  const params = useParams()
  const isEdit = Boolean(params.initiativeId)

  const infoTitleTranslation = useTranslation('form.head.info.title')

  return (
    <>
      <Link to={'/'}>
        <img src={Logo} alt="logo" />
      </Link>
      <Text headingSize="l" tagName="h1">
        <DynamicTranslations id="form.head.title" />
      </Text>
      <Text bodySize="xl">
        <DynamicTranslations id="form.head.sub_title1" /> <br />
        <DynamicTranslations id="form.head.sub_title2" /> <br />
        <DynamicTranslations id="form.head.sub_title3" />
      </Text>
      <div className={styles.GuideButtons}>
        <Button
          href="https://iweof.sharepoint.com/:b:/r/teams/o365g_retailsustainabilitymatrix_issemal/Shared%20Documents/General/04%20Fair%20%26%20Equal/2.%20Social%20Impact%20IKEA%20x%20Neighborhoods/4.%20Social%20Impact%20Performance/Guiding%20material/Guideline%20Reporting%20Social%20Impact%20in%20the%20local%20Neighborhoods%20latest.pdf?csf=1&web=1&e=NiXYhp"
          target="_blank"
          ssrIcon={BookmarkIcon}
        >
          <DynamicTranslations id="form.head.guideline" />
        </Button>
        <Button
          href="https://iweof.sharepoint.com/:b:/t/o365g_retailsustainabilitymatrix_issemal/EXr__6oS6YVOinTSa3001OgBFQtALZV0z3OfWWuOa7jHDQ?e=zrnDW4"
          target="_blank"
          ssrIcon={InfoCircleIcon}
        >
          <DynamicTranslations id="form.head.quick_guideline" />
        </Button>
      </div>
      <Show when={!isEdit}>
        <InlineMessage
          className={styles.InfoWrap}
          body={
            <ul className={styles.InfoList}>
              <li>
                <DynamicTranslations id="form.head.info.point1" />
              </li>
              <li>
                <DynamicTranslations id="form.head.info.point2" />
              </li>
              <li>
                <DynamicTranslations id="form.head.info.point3" />
                <ol>
                  <li>
                    <DynamicTranslations id="form.head.info.point3.sub_point1" />
                  </li>
                  <li>
                    <DynamicTranslations id="form.head.info.point3.sub_point2" />
                  </li>
                  <li>
                    <DynamicTranslations id="form.head.info.point3.sub_point3" />
                  </li>
                  <li>
                    <DynamicTranslations id="form.head.info.point3.sub_point4" />
                  </li>
                </ol>
              </li>
            </ul>
          }
          ssrIcon={InfoCircleIcon}
          title={infoTitleTranslation}
          variant="informative"
        />
      </Show>
    </>
  )
}
