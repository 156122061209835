import React from 'react'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { subscribeForDataReporting } from '@/pages/Analytics/services'
import { mapFromFilters } from '@/pages/Initiatives/utils/mapper'
import { mapToDataItem } from '@/pages/Analytics/utils/mappers'
import { analyticsSlice } from '@/pages/Analytics/slices/analytics'
import { Unsubscribe } from '@firebase/firestore'

export const DataReportingProvider: React.FC<React.PropsWithChildren> = props => {
  const filters = useSelector((state: RootState) => state.filters.filters)
  const unsub = React.useRef<Unsubscribe | null>(null)

  React.useEffect(() => {
    const filersMapped = mapFromFilters(filters)
    if (unsub.current) unsub.current()

    unsub.current = subscribeForDataReporting(filersMapped, data => {
      const mapped = mapToDataItem(data)
      store.dispatch(analyticsSlice.actions.setData(mapped))
    })
  }, [filters])

  React.useEffect(
    () => () => {
      if (unsub.current) unsub.current()
      store.dispatch(analyticsSlice.actions.reset())
    },
    []
  )

  return <>{props.children}</>
}
