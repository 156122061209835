import React from 'react'
import Text from '@ingka/text'

import styles from '../../styles/Modal.module.scss'
import { DynamicTranslations } from '@/components/translations/Translations'

export const CustomersCount: React.FC = () => {
  return (
    <>
      <Text tagName="h1" headingSize="l">
        <DynamicTranslations id="guide.change_maker.title" />
      </Text>
      <Text bodySize="l" style={{ marginBottom: '40px' }}>
        <DynamicTranslations id="guide.change_maker.body1" withTags />
      </Text>
      <div className={styles.Table}>
        <DynamicTranslations id="guide.change_maker.table_head" className={styles.TableRowHead} withTags />
        <DynamicTranslations id="guide.change_maker.table_body" className={styles.TableRow} withTags />
      </div>
    </>
  )
}
