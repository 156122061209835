import React from 'react'
import styles from '../styles/Explore.module.scss'
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal'
import Button from '@ingka/button'
import { DynamicTranslations, useTranslation } from '@/components/translations/Translations'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { exploreSlice } from '@/pages/Explore/slices/explore'
import Accordion, { AccordionItem } from '@ingka/accordion'
import { Form, Formik } from 'formik'
import { RadioGroup } from '@/components/form/radio/RadioGroup'
import { getCurrentFYOptions } from '@/utils/dates'
import { FILTER_NAME } from '@/pages/Explore/constants/filters'
import { STATUSES_OPTIONS } from '@/constants/statuses'
import {
  DURATION_OPTIONS,
  LEVEL_OF_CHANGE_OPTIONS,
  MOVEMENT_OPTIONS,
  PEOPLE_IMPACTED_OPTIONS,
  TYPE_OF_INITIATIVE_OPTIONS
} from '@/pages/ReportForm/constants/options'
import { CheckboxGroup } from '@/components/form/checkbox/CheckboxGroup'
import { InputField } from '@/components/form/input/InputField'
import { clearFilters, handleFilterData } from '@/pages/Explore/utils/filter'
import { FilterObserver } from '@/pages/Explore/components/FilterObserver'
import { UnitsField } from '@/pages/Explore/components/UnitsField'

export const Filters = () => {
  const headTitle = useTranslation('explore.filters.modal_title')
  const open = useSelector((state: RootState) => state.explore.filterOpen)
  const filters = useSelector((state: RootState) => state.explore.filters)
  const count = useSelector((state: RootState) => state.explore.initiatives.length)
  const { countries, functionCodes } = useSelector((state: RootState) => state.sites)

  const handleClose = () => {
    store.dispatch(exploreSlice.actions.setFiltersModal(false))
  }

  const header = <ModalHeader ariaCloseTxt="Close modal" title={headTitle} />

  const getTitle = (id: string) => {
    return <DynamicTranslations id={`explore.filters.${id}`} />
  }

  return (
    <Modal handleCloseBtn={handleClose} visible={open} className={styles.ExploreFilters}>
      <Sheets footer={null} header={header}>
        <ModalBody>
          <Formik initialValues={filters} enableReinitialize onSubmit={handleFilterData}>
            {() => {
              return (
                <Form>
                  <FilterObserver />

                  <Accordion className={styles.Accordion}>
                    <AccordionItem id={FILTER_NAME.report_period} title={getTitle(FILTER_NAME.report_period)}>
                      <CheckboxGroup name={FILTER_NAME.report_period} list={getCurrentFYOptions()} limitedPreview={5} />
                    </AccordionItem>

                    <AccordionItem id={FILTER_NAME.status} title={getTitle(FILTER_NAME.status)}>
                      <CheckboxGroup name={FILTER_NAME.status} list={STATUSES_OPTIONS} withTranslations />
                    </AccordionItem>

                    <AccordionItem id={FILTER_NAME.country_code} title={getTitle(FILTER_NAME.country_code)}>
                      <RadioGroup name={FILTER_NAME.country_code} list={countries} limitedPreview={5} />
                    </AccordionItem>

                    <AccordionItem id={FILTER_NAME.function_code} title={getTitle(FILTER_NAME.function_code)}>
                      <CheckboxGroup name={FILTER_NAME.function_code} list={functionCodes} />
                    </AccordionItem>

                    <UnitsField id={FILTER_NAME.site} title={getTitle(FILTER_NAME.site)} />

                    <AccordionItem id={FILTER_NAME.people_impacted} title={getTitle(FILTER_NAME.people_impacted)}>
                      <CheckboxGroup
                        name={FILTER_NAME.people_impacted}
                        list={PEOPLE_IMPACTED_OPTIONS}
                        limitedPreview={5}
                      />
                    </AccordionItem>

                    <AccordionItem
                      id={FILTER_NAME.people_supported_count}
                      title={getTitle(FILTER_NAME.people_supported_count)}
                    >
                      <InputField
                        id={FILTER_NAME.people_supported_count}
                        type="number"
                        name={FILTER_NAME.people_supported_count}
                      />
                    </AccordionItem>

                    <AccordionItem id={FILTER_NAME.movement_supported} title={getTitle(FILTER_NAME.movement_supported)}>
                      <CheckboxGroup name={FILTER_NAME.movement_supported} list={MOVEMENT_OPTIONS} />
                    </AccordionItem>

                    <AccordionItem id={FILTER_NAME.type_of_initiative} title={getTitle(FILTER_NAME.type_of_initiative)}>
                      <CheckboxGroup
                        name={FILTER_NAME.type_of_initiative}
                        list={TYPE_OF_INITIATIVE_OPTIONS}
                        limitedPreview={5}
                      />
                    </AccordionItem>

                    <AccordionItem id={FILTER_NAME.spent} title={getTitle(FILTER_NAME.spent)}>
                      <InputField id={FILTER_NAME.spent} type="number" name={FILTER_NAME.spent} />
                    </AccordionItem>

                    <AccordionItem id={FILTER_NAME.level} title={getTitle(FILTER_NAME.level)}>
                      <CheckboxGroup name={FILTER_NAME.level} list={LEVEL_OF_CHANGE_OPTIONS} />
                    </AccordionItem>

                    <AccordionItem id={FILTER_NAME.duration} title={getTitle(FILTER_NAME.duration)}>
                      <CheckboxGroup name={FILTER_NAME.duration} list={DURATION_OPTIONS} />
                    </AccordionItem>
                  </Accordion>

                  <div className={styles.Footer}>
                    <Button type="primary" htmlType="submit">
                      <DynamicTranslations id="explore.filters.view" /> {count}
                    </Button>
                    <Button onClick={clearFilters}>
                      <DynamicTranslations id="explore.filters.clear" />
                    </Button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </ModalBody>
      </Sheets>
    </Modal>
  )
}
