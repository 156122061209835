import React from 'react'

import styles from '../styles/Analytics.module.scss'
import { ContentTabs } from '@/pages/Analytics/components/ContentTabs'
import { DataReportingProvider } from '@/pages/Analytics/providers/DataReportingProvider'
import { QuickDataCard } from '@/pages/Analytics/components/QuickDataCard'
import { getChangeMakers, getPositivelyImpacted } from '@/pages/Analytics/utils/dataHandlers'
import { DetailsModal } from '@/pages/Analytics/components/DetailsModal'

export const AnalyticsPage = () => {
  return (
    <DataReportingProvider>
      <DetailsModal />
      <AnalyticsPageContent />
    </DataReportingProvider>
  )
}

export const AnalyticsPageContent = () => {
  return (
    <div className={styles.AnalyticsPageWrap}>
      <div className={styles.QuickInfoBlock}>
        <QuickDataCard
          subTitle="data_reporting.social_impact.sub_title"
          title="data_reporting.social_impact.title"
          handler={getPositivelyImpacted}
        />
        <QuickDataCard
          subTitle="data_reporting.change_maker.sub_title"
          title="data_reporting.change_maker.title"
          handler={getChangeMakers}
        />
      </div>
      <ContentTabs />
    </div>
  )
}
