import { getFirestore, Timestamp, Firestore, getDoc, setDoc, doc } from 'firebase/firestore'
import { User, Auth, getAuth, OAuthProvider, signInWithPopup } from 'firebase/auth'
import { updateDoc } from '@firebase/firestore'
import * as process from 'process'
import { UserData } from '@/types/user'
import { firebaseApp } from '@/storage/firebase'

const tenantId = process.env.REACT_APP_TENANT_ID || ''
let auth: Auth
export let db: Firestore

export async function init(): Promise<User> {
  auth = getAuth(firebaseApp)
  db = getFirestore(firebaseApp)

  return new Promise((resolve, reject) =>
    auth.onAuthStateChanged(user => {
      if (!user) {
        reject()
      } else {
        resolve(user)
      }
    })
  )
}

export const openSignInPopup = async () => {
  // This function will redirect away from the page, so any code after calling this won't run
  const provider = new OAuthProvider('microsoft.com')
  provider.setCustomParameters({
    tenant: tenantId
  })
  provider.addScope('user.read')
  return signInWithPopup(auth, provider)
}

export interface LocationOption {
  id: string
  label: string
  country: string
}

export type InitiativeStatus = 'draft' | 'submitted' | 'rejected' | 'accepted' | 'published'

export interface InitiativeBase {
  userId: string
  status: InitiativeStatus
  country: string
  rejectReason?: string
}

export interface InitiativeVersion {
  locationId: string
  title: string
  description: string
  created: Timestamp
}

export interface Initiative extends InitiativeBase, InitiativeVersion {
  id: string
}

export const initializeWithUserData = async () => {
  const user = await init()
  return getFirebaseUser(user)
}

export async function getFirebaseUser(user: User) {
  try {
    const firebaseUserData = await getUser(user.uid)

    if (firebaseUserData?.photo_url !== user.photoURL) {
      return await updateUserField(user.uid, { photo_url: user.photoURL })
    }

    if (firebaseUserData) {
      return firebaseUserData
    }

    throw new Error('No user found')
  } catch (err) {
    if ((err as Error).message === 'No user found') {
      const newUser = {
        name: user.displayName || 'new user',
        role: 'user' as const,
        photo_url: user.photoURL
      }

      try {
        return await createUserOrUpdateUser(user.uid, newUser)
      } catch (err) {
        throw new Error('Cannot create a new user')
      }
    } else {
      throw new Error('Unexpected error')
    }
  }
}

async function getUser(userId: string): Promise<UserData | void> {
  const user = await getDoc(doc(db, 'users', userId))
  const admin = await getDoc(doc(db, 'admins', userId))
  if (!user.data()) return
  return { id: user.id, ...user.data(), ...admin.data() } as UserData
}

export async function createUserOrUpdateUser(userId: string, userData: Omit<UserData, 'id'>): Promise<UserData> {
  await setDoc(doc(db, 'users', userId), userData)

  return { id: userId, ...userData }
}

export async function updateUserField(userId: string, userData: Partial<UserData>): Promise<UserData> {
  const ref = doc(db, 'users', userId)
  await updateDoc(ref, userData)
  const user = await getDoc(ref)

  return { ...(user.data() as UserData), id: userId }
}
