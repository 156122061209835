import React from 'react'
import { getInitiative } from '@/pages/ReportForm/services'
import { formSlice } from '@/pages/ReportForm/slices/form'
import { mapDataToForm, mapToSiteOptions } from '@/pages/ReportForm/utils/mappers'
import { useParams } from 'react-router-dom'
import { RootState, store } from '@/storage/store'
import { useSelector } from 'react-redux'

export const FormProvider: React.FC<React.PropsWithChildren> = props => {
  const { children } = props
  const { initiativeId } = useParams()
  const { sites: siteList, countries } = useSelector((state: RootState) => state.sites)

  React.useEffect(() => {
    ;(async () => {
      if (siteList.length === 0) return

      try {
        store.dispatch(formSlice.actions.setLoading(true))

        const sites = mapToSiteOptions(siteList)

        if (initiativeId) {
          const data = await getInitiative(initiativeId)
          const initiative = mapDataToForm(data, sites, countries)
          store.dispatch(formSlice.actions.setInitiative({ initiative, sites, userId: data.user_id }))
          store.dispatch(formSlice.actions.setAttachments(data.attachments || []))
          return
        }

        store.dispatch(formSlice.actions.setSites(sites))
      } catch (e) {
        console.error(e)
      } finally {
        store.dispatch(formSlice.actions.setLoading(false))
      }
    })()

    return () => {
      store.dispatch(formSlice.actions.reset())
    }
  }, [siteList.length])

  return <>{children}</>
}
