import { AnalyticsRecordItemType } from '@/types/analytics'
import { IMPACT_TYPES } from '@/constants/common'

type Handler = (data: AnalyticsRecordItemType[]) => string

export const getChangeMakers: Handler = data => {
  return data
    .reduce((result, current) => {
      return result + current.change_makers_count
    }, 0)
    .toString()
}

export const getPositivelyImpacted: Handler = data => {
  const improveCount = data
    .filter(item => item.impact_type === IMPACT_TYPES.improve)
    .reduce((result, current) => (result += current.participants_count), 0)
  const transformCount = data
    .filter(item => item.impact_type === IMPACT_TYPES.transform)
    .reduce((result, current) => (result += current.participants_count), 0)

  return (improveCount + transformCount).toString()
}
