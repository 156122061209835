import React from 'react'
import Tabs, { Tab, TabPanel } from '@ingka/tabs'
import styles from '../styles/Analytics.module.scss'
import { AnalysisChartCard } from '@/pages/Analytics/components/AnalysisChartCard'
import { ReportingStatusChartCard } from '@/pages/Analytics/components/ReportingStatusChartCard'
import { PPITable } from '@/pages/Analytics/components/Table/PPITable'
import { PSTable } from '@/pages/Analytics/components/Table/PSTable'
import { DynamicTranslations } from '@/components/translations/Translations'

export const ContentTabs = () => {
  const tabs = [
    <Tab key="impacted-tab" tabPanelId="impacted" text={<DynamicTranslations id="data_reporting.tabs.ppp" />} />,
    <Tab
      key="supported-tab"
      tabPanelId="supported"
      text={<DynamicTranslations id="data_reporting.tabs.people_supported" />}
    />
  ]

  const panels = [
    <TabPanel key="impacted" tabPanelId="impacted">
      <div className={styles.ChartsBlock}>
        <AnalysisChartCard />
        <ReportingStatusChartCard />
      </div>
      <PPITable />
    </TabPanel>,
    <TabPanel key="supported" tabPanelId="supported">
      <div className={styles.ChartsBlock}>
        <AnalysisChartCard withConnect />
        <ReportingStatusChartCard />
      </div>
      <PSTable />
    </TabPanel>
  ]

  return <Tabs className={styles.Tabs} tabs={tabs} tabPanels={panels} />
}
