import React from 'react'
import { AnalyticsRecordItemType } from '@/types/analytics'
import styles from '@/pages/Analytics/styles/Analytics.module.scss'
import Text from '@ingka/text'
import { useSelector } from 'react-redux'
import { RootState } from '@/storage/store'
import { DynamicTranslations } from '@/components/translations/Translations'

type Props = {
  title: string
  subTitle: string
  handler: (data: AnalyticsRecordItemType[]) => string
}

export const QuickDataCard: React.FC<Props> = props => {
  const { title, subTitle, handler } = props
  const data = useSelector((state: RootState) => state.analytics.data)
  const value = handler(data)

  return (
    <div className={styles.QuickInfoCard}>
      <div className={styles.LeftSide}>
        <div className={styles.Title}>
          <DynamicTranslations id={subTitle} />
        </div>
        <Text headingSize="s">
          <DynamicTranslations id={title} />
        </Text>
      </div>
      <div className={styles.RightSide}>
        <span>{value}</span>
      </div>
    </div>
  )
}
