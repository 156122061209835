import React from 'react'
import styles from '@/pages/Analytics/styles/Analytics.module.scss'
import Text from '@ingka/text'
import { DonutChart } from '@/pages/Analytics/components/DonutChart'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { getReportedStatusUnits, getReportingStatusCountries } from '@/pages/Analytics/utils/donut'
import { ALL_OPTION } from '@/constants/common'
import { analyticsSlice } from '@/pages/Analytics/slices/analytics'
import { DynamicTranslations } from '@/components/translations/Translations'

export const ReportingStatusChartCard = () => {
  const data = useSelector((state: RootState) => state.analytics.data)
  const filters = useSelector((state: RootState) => state.filters.filters)
  const { countries, sites } = useSelector((state: RootState) => state.sites)
  const withCountryFilter = filters.country_code && filters.country_code?.value !== ALL_OPTION.value
  const series = withCountryFilter
    ? getReportedStatusUnits(data, sites, filters)
    : getReportingStatusCountries(data, countries)

  return (
    <div className={styles.ChartCard}>
      <Text className={styles.Head} headingSize="m">
        <DynamicTranslations id="data_reporting.reporting_status.title" />
        {withCountryFilter ? `: ${filters.country_code?.label}` : ''}
      </Text>
      <div className={styles.ChartWrap}>
        <DonutChart values={series} title={withCountryFilter ? 'units' : 'countries'} />
        <div className={styles.ChartInfo}>
          {series.map(item => {
            return (
              <div key={`info-card-${item.color}`} className={styles.InfoItem}>
                <div className={styles.InfoItemHead}>
                  <div className={styles.Indicator} style={{ backgroundColor: item.color }} />
                  <Text className={styles.Name} headingSize="s">
                    <DynamicTranslations id={item.name} />
                  </Text>
                </div>
                <Text headingSize="m">{item.value}</Text>
              </div>
            )
          })}
          <Text
            style={{ cursor: 'pointer' }}
            headingSize="xs"
            onClick={() => store.dispatch(analyticsSlice.actions.toggleCountriesModal(true))}
          >
            Learn more ›
          </Text>
        </div>
      </div>
    </div>
  )
}
