import React from 'react'
import { Form, Formik } from 'formik'
import Button from '@ingka/button'
import Loading, { LoadingBall } from '@ingka/loading'

import { ReportForm } from './ReportForm'
import styles from '../styles/NewReport.module.scss'
import { FormProvider } from '@/pages/ReportForm/providers/FormProvider'
import { DetailsModal } from '@/pages/ReportForm/components/DetailsModal'
import { DraftReportSchema, NewReportSchema } from '@/pages/ReportForm/utils/validation'
import { editData } from '@/pages/ReportForm/utils/submit'
import { InitiativeFormType } from '@/types/initiative'
import { SUBMIT_TYPES } from '@/pages/ReportForm/constants/types'
import { useNavigate, useParams } from 'react-router-dom'
import { useHeaderSettings } from '@/components/header/provider'
import { initialValues } from '@/pages/ReportForm/utils/mappers'
import { FormHeadInfo } from '@/pages/ReportForm/components/FormHeadInfo'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { useCanAccessPage } from '@/pages/ReportForm/utils/conditions'
import { SubmitConfirmation } from '@/pages/ReportForm/components/SubmitConfirmation'
import { formSlice } from '@/pages/ReportForm/slices/form'
import { STATUSES } from '@/constants/statuses'
import { ConfirmDelete } from '@/pages/ReportForm/components/Fields/ConfirmDelete'
import { DynamicTranslations } from '@/components/translations/Translations'
import { Show } from '@/components/conditions/Show'

const Report: React.FC = () => {
  const isLoading = useSelector((state: RootState) => state.form.isLoading)
  const initiative = useSelector((state: RootState) => state.form.initiative)
  const isOwner = useSelector((state: RootState) => state.form.userId === state.auth.user?.id || !state.form.userId)
  const [type, setType] = React.useState<string>(SUBMIT_TYPES[0])
  const navigate = useNavigate()
  const { initiativeId } = useParams()

  useHeaderSettings(false)
  useCanAccessPage()

  const afterSubmit = () => {
    navigate('/')
  }

  if (isLoading) {
    return (
      <div className={styles.NewReportWrapper}>
        <div className={styles.FormBackground} />
        <div className={styles.FormWrapper}>
          <form>
            <FormHeadInfo />
            <Loading text="Loading">
              <LoadingBall />
            </Loading>
          </form>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.NewReportWrapper}>
      <div className={styles.FormBackground} />
      <div className={styles.FormWrapper}>
        <Formik<InitiativeFormType>
          enableReinitialize
          initialValues={initiative || initialValues}
          validationSchema={type === SUBMIT_TYPES[0] ? DraftReportSchema : NewReportSchema}
          onSubmit={(data, handlers) => {
            const dataProviderCondition = initiative?.status === STATUSES[0] && type !== SUBMIT_TYPES[0]
            const countryReviewerCondition = !isOwner

            if (dataProviderCondition || countryReviewerCondition) {
              store.dispatch(formSlice.actions.setConfirmSubmit(true))
              return
            }

            editData(String(initiativeId), type, afterSubmit)(data, handlers)
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form>
              <SubmitConfirmation type={type} />
              <ReportForm />
              <div className={styles.SaveButtons}>
                <Show when={isOwner}>
                  <Button
                    disabled={isSubmitting}
                    onClick={e => {
                      setType(SUBMIT_TYPES[0])
                      setTimeout(() => handleSubmit(e), 0)
                    }}
                  >
                    <DynamicTranslations id="form.save_draft" />
                  </Button>
                </Show>
                <Button
                  disabled={isSubmitting}
                  type="primary"
                  onClick={e => {
                    setType(SUBMIT_TYPES[1])
                    setTimeout(() => handleSubmit(e), 0)
                  }}
                >
                  <DynamicTranslations id="form.submit_initiative" />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export const EditReport = () => {
  return (
    <FormProvider>
      <Report />
      <DetailsModal />
      <ConfirmDelete />
    </FormProvider>
  )
}
