import React from 'react'
import Modal, { ModalHeader, Prompt } from '@ingka/modal'

import styles from '../styles/Modal.module.scss'
import Text from '@ingka/text'
import { useSelector } from 'react-redux'
import { RootState, store } from '@/storage/store'
import { getCountriesWithInitiatives, getUnitsWithInitiatives } from '@/pages/Analytics/utils/modal'
import clx from 'classnames'
import { analyticsSlice } from '@/pages/Analytics/slices/analytics'
import { ALL_OPTION } from '@/constants/common'
import { Show } from '@/components/conditions/Show'
import { DynamicTranslations } from '@/components/translations/Translations'

export const DetailsModal: React.FC = () => {
  const showCountriesModal = useSelector((state: RootState) => state.analytics.showCountriesModal)
  const functionCode = useSelector((state: RootState) => state.filters.filters.function_code)
  const countryFilter = useSelector((state: RootState) => state.filters.filters.country_code)

  const isIngkaCentres = functionCode?.value === 'Ingka Centres'
  const isCountiesTable = !countryFilter?.value || countryFilter?.value === ALL_OPTION.value
  const title = isIngkaCentres
    ? 'data_reporting.details.places_title'
    : isCountiesTable
    ? 'data_reporting.details.countries_title'
    : 'data_reporting.details.units_title'

  const handleClose = () => {
    store.dispatch(analyticsSlice.actions.toggleCountriesModal(false))
  }

  const header = <ModalHeader ariaCloseTxt="Close confirmation" />

  return (
    <Modal visible={showCountriesModal} handleCloseBtn={handleClose}>
      <Prompt className={styles.Modal} title={<DynamicTranslations id={title} />} footer={null} header={header}>
        <div className={styles.Body}>
          <Show when={isCountiesTable}>
            <CountriesTable isIngkaCentres={isIngkaCentres} />
          </Show>
          <Show when={!isCountiesTable}>
            <UnitsTable />
          </Show>
        </div>
      </Prompt>
    </Modal>
  )
}

type CountriesProps = {
  isIngkaCentres: boolean
}

const CountriesTable: React.FC<CountriesProps> = props => {
  const { isIngkaCentres } = props

  const data = useSelector((state: RootState) => state.analytics.data)
  const countries = useSelector((state: RootState) => state.sites.countries)

  const listData = getCountriesWithInitiatives(data, countries)

  return (
    <div className={styles.Table}>
      <div className={styles.HeadRow}>
        <div className={styles.HeadItem}>
          <DynamicTranslations id="data_reporting.details.countries_table.abbreviation" />
        </div>
        <div className={styles.HeadItem}>
          <DynamicTranslations id="data_reporting.details.countries_table.country" />
        </div>
        <div className={styles.HeadItem}>
          <DynamicTranslations id="data_reporting.details.countries_table.number" />
        </div>
      </div>
      {listData.map(item => (
        <div key={`modal - info -${item.value}`} className={styles.TableRow}>
          <div className={styles.RowCel}>
            <Text bodySize="m">
              {item.value}
              {isIngkaCentres ? ' - C' : ''}
            </Text>
          </div>
          <div className={styles.RowCel}>
            <Text bodySize="m">{item.label}</Text>
          </div>
          <div className={clx(styles.RowCel, !item.initiativesCount ? styles.IsZero : null)}>
            <Text bodySize="m">{item.initiativesCount}</Text>
          </div>
        </div>
      ))}
    </div>
  )
}

const UnitsTable = () => {
  const data = useSelector((state: RootState) => state.analytics.data)
  const units = useSelector((state: RootState) => state.sites.sites)
  const filters = useSelector((state: RootState) => state.filters.filters)

  const listData = getUnitsWithInitiatives(data, units, filters)

  return (
    <div className={styles.Table}>
      <div className={clx(styles.HeadRow, styles.Units)}>
        <div className={styles.HeadItem}>
          <DynamicTranslations id="data_reporting.details.units_table.unit" />
        </div>
        <div className={styles.HeadItem}>
          <DynamicTranslations id="data_reporting.details.countries_table.number" />
        </div>
      </div>
      {listData.map(item => (
        <div key={`modal - info -${item.value}`} className={clx(styles.TableRow, styles.Units)}>
          <div className={styles.RowCel}>
            <Text bodySize="m">{item.value}</Text>
          </div>
          <div className={clx(styles.RowCel, !item.initiativesCount ? styles.IsZero : null)}>
            <Text bodySize="m">{item.initiativesCount}</Text>
          </div>
        </div>
      ))}
    </div>
  )
}
